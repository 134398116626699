<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
              <b-button
                class="ml-1"
                variant="primary"
                :disabled="isLoading"
                @click="exportStudentsCSV"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                <span
                  class="ml-25 align-middle"
                >Export {{ camperStudent(2) }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchStudentsList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: camper name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.avatar"
          />
          <b-link
            :to="{ name: 'admin-student-profile', params: { id: data.item.id } }"
          >{{ data.item.full_name }}</b-link>
        </template>

        <!-- Column: gender -->
        <template #cell(gender)="data">
          {{ data.item.extra_user_info.gender }}
        </template>

        <!-- Column: registration_date -->
        <template #cell(registration_date)="data">
          <span>{{ data.item.registration_date | shortDate }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('read', permissionSubjects.LoginAsChild)"
              @click="loginAs(data.item.id, data.item.role.name)"
            >
              <span>Log In as {{ camperStudent(1) }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.parent"
              :to="{ name: 'admin-parent-profile', params: { id: data.item.parent.id } }"
            >
              <span>View Parent</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Child)"
              @click="showDeleteConfirmationModal(data.item)"
            >
              <span>Delete {{ camperStudent(1) }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <confirmation-modal
      v-if="deletableStudent"
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this user (${deletableStudent.full_name})? ` +
      'All associated applications will also be removed.'"
      :is-loading="isUserDeleteLoading"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="deleteItem"
    />

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BAvatar, BLink, BButton, BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { loginAs } from '@core/mixins/loginAs'
import { filters } from '@core/mixins/filters'

import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/students/studentsStoreModule'
import useStudentsList from '@/views/admin/students/students-list/useStudentsList'
import useUsersList from '@/views/admin/users/users-list/useUsersList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  name: 'StudentsList',
  components: {
    ConfirmationModal,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BLink,
    BButton,
    BSpinner,

    vSelect,

    TableSpinner,
  },
  mixins: [downloadExportFile, loginAs, filters],
  data() {
    return {
      isLoading: false,
      confirmationModalHide: false,
      isUserDeleteLoading: false,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-students'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchStudentsList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      programId,

      statusFilter,
      actionOptions,

      refetchData,
    } = useStudentsList(root)

    const { exportUsers } = useUsersList()

    return {
      fetchStudentsList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      programId,

      statusFilter,
      actionOptions,

      refetchData,
      exportUsers,
    }
  },
  computed: {
    ...mapGetters({
      deletableStudent: 'app-students/getDeletableUser',
    }),
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    showDeleteConfirmationModal(parent) {
      this.$store.commit('app-students/SET_DELETABLE_USER', parent)
      this.$nextTick(() => {
        this.$bvModal.show('confirmation')
      })
    },
    async deleteItem() {
      if (this.deletableStudent.id) {
        try {
          this.isUserDeleteLoading = true
          await this.$store.dispatch('app-students/deleteUser', { id: this.deletableStudent.id })
            .then(() => {
              this.refetchData()
            })
          this.$store.commit('app-students/SET_DELETABLE_USER', null)
        } finally {
          this.isUserDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
    // async deleteItem(ID) {
    //   await axios
    //     .delete(`auth/users/${ID}`)
    //     .then(() => {
    //       this.refetchData()
    //     })
    //     .catch(error => error)
    // },
    async exportStudentsCSV() {
      const queryParams = {
        sortBy: 'name',
        sortDesc: 0,
        role: 'child',
        // onlyStudents: true,
        programId: this.programId,
        // hasApplications: true,
      }
      if (this.searchQuery) queryParams.name = this.searchQuery
      this.isLoading = true

      await this.exportUsers(queryParams).then(response => {
        if (response.status === 200) {
          this.downloadFile(response.data, this.camperStudent(2), 'csv')
          this.isLoading = false
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    updatePerPage(val) {
      localStorage.setItem('studentsPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('studentsPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (from.name === 'admin-program' && to.params.id) {
  //       // eslint-disable-next-line no-param-reassign
  //       vm.filterDataProgram = to.params.id
  //     }
  //   })
  // },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
